import React from 'react';
import { BrowserRouter } from "react-router-dom";
import AppContainer from "./layout/AppContainer";

function App() {

 if (window.location.href.substr(0, 5) !== 'https') {
   window.location.href = window.location.href.replace('http', 'https');
  }

  return (
    <BrowserRouter>
      <div id="App" style={{ backgroundColor: "#f5f5f5" }}>
        <AppContainer />
      </div>
    </BrowserRouter>
  );
}

export default App;
