
import { createTheme } from '@material-ui/core/styles'

export function createAppTheme() {
  const validPrimaryColor = "#3685B5";
  const validSecondaryColor = "#ffffff";

  return createTheme({
    typography: {
      "fontFamily": `"Asap", "Helvetica", "Arial", sans-serif`,
      "fontSize": 14,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500
     },

    palette: {
      type: "light",
      primary: {
        main: validPrimaryColor
      },
      secondary: {
        main: "#A4243B"
      },
      alternate: {
        main: "#32936F"
      },
      background: {
        default: validSecondaryColor
      }
    }
  });
}
