import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import ItemCard from "./ItemCard";
import { makeStyles } from "@material-ui/core/styles";
import { ImageList } from "@material-ui/core/";
import PageLoader from "../../layout/PageLoader";
import PageSkeleton from "../../layout/PageSkeleton";
import { incrementCartItems } from "../../store/actions/cartActions";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import HomeCarousel from "../../layout/Carousel";
import Search from "./Search";
import CategoryScroller from "./CategoryScroller";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  overlay: {
    zIndex: 100,
  },
  root: {
    marginBottom: "100px",
    color: "#3b5998",
    overflow: "hidden",
  },
  products: {
    marginTop: 15,
    margin: "0 auto",
  },
  main: {
    display: "flex",
    flexWrap: "wrap",
  },
  cartContainer: {
    position: "fixed",
    top: 0,
    backgroundColor: "#f5f5f5",
    width: "100%",
    height: "100%",
    overflowX: "hidden",
  },
  cart: {
    margin: "0 auto",
    paddingTop: "200px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 100,
    },
  },
  grid: {
    margin: "0 auto",
    justifyContent: "center",
  },
  filter: {
    margin: "0 auto",
    zIndex: 4000,
  },
  banners: {
    marginTop: 30,
    [theme.breakpoints.up("md")]: {
      maxWidth: "60%",
      margin: "0 auto",
      marginTop: 10,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "75%",
      margin: "0 auto",
      marginTop: 10,
    },
  },
  covidBanner: {
    width: "100%",
    height: 34,
    marginTop: 32,
    padding: 5,
    position: "absolute",
    zIndex: 1000,
    //backgroundColor: "#3685B5",
    backgroundColor: "#454545", // black friday color
    // backgroundColor: "#5ae866", //christmas
    color: "white",
    // color: "#bd0421", //christmas
    //fontWeight: 600, //christmas
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: 33,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 33,
    },
  },

  closeIcon: {
    fontSize: 10,
    marginLeft: "96%",
    marginTop: "-20px",
  },
}));

const Home = (props) => {
  const classes = useStyles();
  let { inventory, incrementCart, cart } = props;
  let storedItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const [cartValue, setCartValue] = useState(storedItems);
  const [filterValue, setFilterValue] = useState("");
  const [showCovidBanner, setShowCovidBanner] = useState(false);

  const filterProducts = (event) => {
    if (event && event.target) {
      setFilterValue(event.target.value);
    } else {
      setFilterValue(event);
    }
  };

  useEffect(() => {
    if (inventory) {
      setTimeout(() => {
        setHideLoader(true);
      }, 3000);
    }
  }, [inventory]);

  const [hideLoader, setHideLoader] = useState(false);

  const shuffle = (array) => {
    var currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  };

  const sortLowToHigh = (array) => {
    array.sort(function (a, b) {
      return a.salePrice - b.salePrice;
    });
  };

  // turn off shuffle for black friday
  shuffle(inventory);

  // turn on sort for black friday
  //sortLowToHigh(inventory);

  const addToCart = (product) => {
    const cartItems = cartValue.slice();
    let counter = Number(localStorage.getItem("cartCounter"));
    let alreadyInCart = false;
    counter++;
    localStorage.setItem("cartCounter", counter);

    cartItems.forEach((item) => {
      if (item.id === product.id) {
        item.count++;
        alreadyInCart = true;
      }
    });

    if (!alreadyInCart) {
      cartItems.push({ ...product, count: 1 });
    }

    setCartValue(cartItems);
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    incrementCart(counter, cartItems);
  };

  const hideCovidBanner = () => {
    setShowCovidBanner(false);
  };

  return !hideLoader ? (
    <div className={classes.overlay}>
      <PageLoader />
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.main}>
        {props.showFilter ? (
          <Search
            updateShowFilter={props.updateShowFilter}
            filterProducts={filterProducts}
            className={classes.filter}
          ></Search>
        ) : null}

        {showCovidBanner ? (
          <div className={classes.covidBanner}>
            For COVID-19 updates: www.sacoronavirus.co.za
            <div className={classes.closeIcon} onClick={hideCovidBanner}>
              <CloseIcon />
            </div>
          </div>
        ) : null}

        <div className={classes.banners}>
          <HomeCarousel></HomeCarousel>
        </div>
        <CategoryScroller filterProducts={filterProducts} />

        <div className={classes.products}>
          <ImageList cols={3} className={classes.grid}>
            {inventory
              .filter((item) => {
                if (filterValue === "Sale") {
                  return item.sale === true;
                }
                else  if (filterValue === "New") {
                  if (item && item.new) return  item.new === true;
                }
                else if (filterValue) {
                  let tags = item.tags.toLowerCase();
                  let name = item.name.toLowerCase();

                  return (
                    tags.includes(filterValue.toLowerCase()) ||
                    name.includes(filterValue.toLowerCase())
                  );
                } else return item.tags.includes("");
              })
              .map((item) => {
                if (item.isVisible) {
                  return (
                    <ItemCard
                      item={item}
                      key={item.code}
                      add={addToCart}
                      cart={cart}
                    ></ItemCard>
                  );
                } else return null;
              })}
          </ImageList>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  incrementCart: (counter, items) => {
    dispatch(incrementCartItems(counter, items));
  },
});

const mapStateToProps = (state) => {
  let inventory = state.firestore.ordered.inventory;
  let inStock = [];
  if (inventory) {
    inStock = inventory.filter((item) => {
      return item.amountAvailable > 0;
    });
  }

  return {
    inventory: inStock,
    auth: state.firebase.auth,
    cart: state.cart,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "inventory" }])
)(Home);
