const cartReducer = (state = {}, action) => {
  switch (action.type) {
    case "INCREMENT_CART_ITEMS":
      return { ...state, counter: action.counter, items: action.items };

    case "UPDATE_CART_ITEMS":
      return { ...state, items: action.items };

    default:
      return state;
  }
};
export default cartReducer;
