import React ,{useState}from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../../logo2.png";

const verySmall = 321;
const p20Pro = 365;
const iphone6plus = 414;
const large = 1200;

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    width: 200,
    height: 325,
    fontSize: 20,
    margin: 10,
    marginBottom:20,
    textDecoration: "none",
    [theme.breakpoints.down(p20Pro)]: {
      width: "46%",
      margin: 4
    },
    [theme.breakpoints.up(p20Pro)]: {
      width: "46%",
      margin: 4
    },
    [theme.breakpoints.up(iphone6plus)]: {
      width: 190
    },
    [theme.breakpoints.down(verySmall)]: {
      width: "46%",
      margin: 4
    },
    [theme.breakpoints.up(large)]: {
      width: 280,
      height: 450,
      margin: 6
    }
  },
  image: {
    width: 180,
    height: 180,
    margin: "0 auto",
    [theme.breakpoints.down(p20Pro)]: {
      width: 166,
      height: 166
    },
    [theme.breakpoints.up(p20Pro)]: {
      width: 164,
      height: 164
    },
    [theme.breakpoints.up(iphone6plus)]: {
      width: 190,
      height: 190
    },
    [theme.breakpoints.down(verySmall)]: {
      width: 150,
      height: 150
    },
    [theme.breakpoints.up(large)]: {
      width: 280,
      height: 280
    }
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  name: {
    marginTop: 5,
    //color: "#3685B5",
    color: "#2f2f2f",
    fontSize: 15,
    height: 25,
    padding: 5,
    marginBottom: 25
  },
  actions: {
    marginTop: 2,
    justifyContent: "center",
    padding: 0
  },
  price: {
    marginTop: 15,
    color: "#2f2f2f",
    fontSize: 22,
    marginBottom:10,
    fontWeight: 700
  },
  add: {
    color: "#3498db",
    fontSize: 16
  },
  media: {
    paddingTop: "56.25%", // 16:9
    height: 0
  },
  CardContent: {
    margin: "0 auto",
    textAlign: "center",
    padding: 0
  },
  AddIcon: {},
  saleLayout: {
    width: 130,
    textAlign: "center",
    marginBottom: 5
  },
  saleContainer: {
    position: "relative",
    fontSize: 13,
  //  backgroundColor: "#3685B5",
     backgroundColor: "#454545", // black friday color
  //  backgroundColor: "#bd0421", // christmas / valentines day
    color: "white",
    padding: 5,
    display: "inline-block",
    marginLeft: 6
  },
  newContainer: {
    position: "relative",
    fontSize: 13,
     backgroundColor: "#454545", // black friday color
    //backgroundColor: "#bd0421", // christmas / valentines day
    color: "white",
    padding: 5,
    display: "inline-block",
    marginLeft: 6
  },
  salePrice: {
    marginTop: 2,
    color: "#2f2f2f",
    fontSize: 22,
    marginBottom:10,
    fontWeight: 700
  },
  normalPrice: {
    fontSize: 14,
    textDecoration: "line-through",
    display: "inline-block"
  },

  overlay:{
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'black',
    color:'black',
    opacity: 0.4,
    height: 350,
    [theme.breakpoints.down(p20Pro)]: {
      width: 166,
     // height: 166,
    },
    [theme.breakpoints.up(p20Pro)]: {
      width: 164,
     // height: 164,
    },
    [theme.breakpoints.up(iphone6plus)]: {
      width: 190,
    //  height: 190,
    },
    [theme.breakpoints.down(verySmall)]: {
      width: 160,
     // height: 150,
    },
    [theme.breakpoints.up(large)]: {
       width:  280,
       height: 450
    }
  },
  overlayText : {
    margin: "0 auto",
    textAlign : "center",
    marginTop: 20
  }

}));

const ItemCard = props => {
  let { item } = props;
  const [hover, setHover] = useState(false);
  const classes = useStyles();
  let domain = "https://durbancomics.com/products/";
  let imageLink = domain + item.code + ".png";


  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseExit = () => {
    setHover(false);
  };

  return (
    <Card
      className={classes.root}
      elevation={0}
      component={Link}
      to={"../product/" + item.id}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseExit}
    >
      <CardMedia className={classes.image}>
        <LazyLoadImage
          alt={imageLink}
          effect="black-and-white"
          placeholderSrc={logo}
          className={classes.image}
          src={imageLink} // use normal <img> attributes as props
        />
      </CardMedia>

      <CardContent className={classes.CardContent}>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.name}
        >
          {item.name}
        </Typography>
        { item && item.new ? (
        <div className={classes.newContainer}>NEW</div>
        ): null}
      </CardContent>

      <CardActions disableSpacing className={classes.actions}>
        {item.sale ? (
          <div className={classes.saleLayout}>
            <Typography variant="body2" className={classes.salePrice}>
              R{item.salePrice}.00
            </Typography>

            <Typography variant="body2" className={classes.normalPrice}>
              R{item.price}.00
            </Typography>
            <div className={classes.saleContainer}>SALE</div>
          </div>
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.price}
          >
            R{item.price}.00
          </Typography>
        )}
      </CardActions>


     { hover ? (
        <div className={classes.overlay}>
          
          <div className={classes.overlayText}> R100 </div>
        </div>
      ) : null}

    </Card>
  );
};

export default ItemCard;
