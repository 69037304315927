import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  header: {
    fontSize: 40,
    fontWeight: 700,
    margin: "0 auto",
    padding: 10,
    color: "#F5F5F5",
    [theme.breakpoints.down("sm")]: {
      fontSize: 30
    },
    textAlign: "center"
  },
  sub: {
    fontSize: 15,
    fontWeight: 400,
    margin: "0 auto",
    padding: 10,
    color: "#F5F5F5",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15
    },
    textAlign: "center"
  },
  card: {
    background: "#D44942",
    width: 300,
    margin: "0 auto",
    marginTop: -50,
    padding: 30,
    borderRadius: 10,
    textAlign: "center"
  },

  icon: {
    fontSize: 100,
    margin: "0 auto",
    color: "white"
  }
}));

const PaymentError = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <CancelIcon className={classes.icon} />
        <Typography className={classes.header}>Error</Typography>
        <Typography className={classes.sub}>
          Something went wrong. Please try again
        </Typography>

        <Grid container justify="center" style={{ marginTop: "30px" }}>
          <Button
            className={classes.reset}
            variant="contained"
            color="secondary"
            style={{ textTransform: "none" }}
            component={Link}
            to="../"
          >
            Back to Shop
          </Button>
        </Grid>
      </div>
    </div>
  );
};
export default PaymentError;
