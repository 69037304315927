import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import LogoutButton from "./LogoutButton";
import { useFirestore } from "react-redux-firebase";
import PersonalDetails from "./PersonalDetails";
import PageLoader from "../../layout/PageLoader";
const useStyles = makeStyles(theme => ({
  root: {
    width: "90%",
    minHeight: "100vh",
    margin: "0 auto",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto"
    }
  },
  content: {
    fontSize: 14,
    fontWeight: 500,
    color: "#3685B5",
    padding: 5
  },
  logout: {
    margin: "0 auto",
    paddingBottom: 50,
    marginLeft: "47%",
    backgroundColor: "#E5A718"
  }
}));

const Profile = ({ auth }) => {
  const classes = useStyles();
  const firestore = useFirestore();
  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [suburb, setSuburb] = useState();
  const [city, setCity] = useState();
  const [phone, setPhone] = useState();
  const [hideLoader, setHideLoader] = useState(false);
  let user = {};

  if (auth) {
    user = {
      id: auth.uid,
      email: auth.email
    };
  }

  setTimeout(() => {
    setHideLoader(true);
  }, 2000);

  const userDetails = () => {
    firestore
      .collection("users")
      .doc(user.id)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          if (doc.data().name) {
            setName(doc.data().name);
          }

          setAddress(doc.data().address);
          setSuburb(doc.data().suburb);
          setCity(doc.data().city);
          setPhone(doc.data().phone);
        } else {
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  };
  userDetails();
  return !hideLoader ? (
    <div className={classes.overlay}>
      <PageLoader />
    </div>
  ) : (
    <div className={classes.root}>
      <PersonalDetails
        name={name}
        email={auth.email}
        suburb={suburb}
        address={address}
        city={city}
        phone={phone}
        className={classes.orderList}
      ></PersonalDetails>

      <LogoutButton className={classes.logout} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

export default compose(connect(mapStateToProps))(Profile);
