import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
const mobile = 500;

const styles = (theme) => ({
    filterContainer: {
        width: "100%",
        marginTop: 15,
        marginBottom: 0,
        backgroundColor: "#ffffff",
        padding: 15,
        position: "fixed",
        zIndex: 1001,

        [theme.breakpoints.up(mobile)]: {
            padding: 20,
        },
    },
    formControl: {
        marginLeft: "5%",
        width: "80%",
        margin: "0 auto",
        fontSize: "1em",
        height: 54,
        paddingTop: 5,
        paddingBottom: 5,
    },
    filterText: {
        marginLeft: 10,
        width: "85%",
    },
    close: {
        display: "inline-block",
        marginLeft: 15,
        marginTop: 20,
        color: "#2f2f2f"
    },
});

class Search extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;

        const handleChange = (e) => {
            this.props.filterProducts(e);
        };

        const handleClose = (e) => {
            this.props.updateShowFilter();
        };

        return (
            <div className={classes.filterContainer}>
                <FormControl variant="outlined" className={classes.formControl}>
                    < TextField id="filtetText" label="Browse" variant="outlined"
                        onChange={handleChange} />

                </FormControl>
                <CloseIcon className={classes.close} onClick={handleClose} />
            </div>
        );
    }
}
export default withStyles(styles)(Search);
