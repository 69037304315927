import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFirebase } from "react-redux-firebase";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import logo from "../../loaderWhite.png";

const useStyles = makeStyles(theme => ({
  root: {
    background:
      "radial-gradient(circle, rgba(92,171,217,1) 0%, rgba(54,133,181,1) 49%, rgba(3,64,102,1) 100%)",
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  header: {
    fontSize: 20,
    fontWeight: 700,
    margin: "0 auto",
    padding: 30,
    color: "#F5F5F5",
    textAlign: "center"
  },
  message: {
    textAlign: "center",
    color: "red",
    fontSize: "14px"
  },
  container: {
    width: 300,
    margin: "0 auto"
  },
  text: {
    margin: "0 auto",
    textAlign: "center",
    width: 300,
    marginBottom: 10,
    background: "white",
    borderRadius: 30
  },
  reset: {
    width: 120,
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: "#E5A718",
    color: "white"
  },
  backToLogin: {
    width: 120,
    marginLeft: 20
  },

  finePrint: {
    margin: "0 auto",
    marginTop: "10px",
    textAlign: "center"
  },
  finePrintText: {
    fontSize: 14,
    textDecoration: "none",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: 10
    }
  },
  logo: {
    width: 145,
    margin: "0 auto"
  },
  input: {
    "&::placeholder": {
      textAlign: "center"
    }
  }
}));

const ResetPassword = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const [state, setState] = useState({
    email: ""
  });

  const [errorMessage, setErrorMessage] = React.useState(false);

  const handleChange = e => {
    const { id, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleSubmitClick = e => {
    e.preventDefault();

    firebase
      .auth()
      .sendPasswordResetEmail(state.email)
      .then(user => {
        console.log("email sent ");
        alert("Email Sent");
        window.location.href = "../login";
      })
      .catch(error => {
        var errorMessage = error.message;
        setErrorMessage(errorMessage);
      });
  };

  return (
    <div className={classes.root}>
      <img src={logo} className={classes.logo} alt="logo" />

      <div className={classes.container}>
        <Typography className={classes.header}> RESET PASSSWORD </Typography>

        <TextField
          className={classes.text}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input }
          }}
          InputLabelProps={{ required: false }}
          id="email"
          placeholder="Email"
          type="email"
          fullWidth
          required
          onChange={handleChange}
          variant="outlined"
        />
      </div>

      <Grid container justify="center" style={{ marginTop: "30px" }}>
        <Button
          className={classes.reset}
          variant="contained"
          style={{ textTransform: "none" }}
          onClick={handleSubmitClick}
        >
          Reset
        </Button>

        <Button
          className={classes.backToLogin}
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
          component={Link}
          to="../login"
        >
          Back
        </Button>
      </Grid>

      <Typography className={classes.finePrint}>
        <Link to="../" className={classes.finePrintText}>
          Back to Shop
        </Link>
      </Typography>

      <div className={classes.message}>
        {errorMessage ? <p> {errorMessage}</p> : null}
      </div>
    </div>
  );
};
export default ResetPassword;
