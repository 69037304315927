import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

var firebaseConfig = {
  apiKey: "AIzaSyD664sUzLqhPy4dd2c3hiFERr3hVNHfHuw",
  authDomain: "durban-comics.firebaseapp.com",
  databaseURL: "https://durban-comics.firebaseio.com",
  projectId: "durban-comics",
  storageBucket: "durban-comics.appspot.com",
  messagingSenderId: "685562455853",
  appId: "1:685562455853:web:efbdefb3381e75143215a4",
  measurementId: "G-BVKZMNCLH5"
};
firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({ experimentalForceLongPolling: true }); //add this..

export default firebase;
