import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { incrementCartItems } from "../../store/actions/cartActions";
import PageLoader from "../../layout/PageLoader";
import Modal from "@material-ui/core/Modal";
import success from "../../success.png";
import sad from "../../sad.png";
import ShareIcon from "@material-ui/icons/Share";
import { Divider } from "@material-ui/core";

const verySmall = 321;
const tablet = 768;

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 20,
    minHeight: "100vh",
    [theme.breakpoints.down(tablet)]: {
      background: "white",
    },
  },
  cardRoot: {
    width: 460,
    margin: "0 auto",
    marginTop: 100,
    [theme.breakpoints.down(tablet)]: {
      marginTop: 10,
      width: "90%",
    },
  },
  image: {
    width: 250,
    height: 250,
    borderRadius: 10,
    margin: "0 auto",
    marginTop: 40,
    marginBottom: 20,
    [theme.breakpoints.down(verySmall)]: {
      marginTop: 10,
      width: 240,
      height: 240,
    },
    [theme.breakpoints.up(tablet)]: {
      marginTop: 60,
      width: 250,
      height: 250,
    },
  },
  CardContent: {
    margin: "0 auto",
    textAlign: "center",
    padding: 0,
  },
  name: {
    color: "#3685B5",
    fontSize: 18,
    fontWeight: 700,
    height: 25,
    marginTop: 20,
    marginBottom: 4,
    [theme.breakpoints.down(verySmall)]: {
      fontSize: 16,
      marginBottom: 8,
    },
  },
  price: {
    color: "#222",
    fontSize: 16,
    fontWeight: 800,
    [theme.breakpoints.up(tablet)]: {
      marginTop: 10,
      fontSize: 22,
    },
  },
  description: {
    color: "#222",
    lineHeight: 2,
    fontSize: 14,
    fontWeight: 500,
    marginTop: 10,
    marginBottom: 10,
  },
  reset: {
    width: 120,
    fontSize: 14,
    fontWeight: 600,
    color: "white",
    padding: 10,
  },

  share: {
    marginLeft: 20,
    color: "#414141",
    marginTop: 10,
  },

  addToCart: {
    width: 120,
    marginLeft: 20,
    color: "white",
    padding: 10,
  },
  fakeFooter: {
    height: 100,
    [theme.breakpoints.up(verySmall)]: {
      height: 74,
    },
  },
  modal: {
    backgroundColor: "white",
    position: "absolute",
    padding: 25,
    top: "50%",
    left: "50%",
    marginTop: -150,
    marginLeft: -150,
    width: 300,
    height: 320,
  },
  modalText: {
    textAlign: "center",
    padding: 10,
  },

  modalButton: {
    width: 300,
    marginTop: 10,
    color: "white",
    padding: 10,
  },
  modalButton2: {
    width: 300,
    marginTop: 10,
    color: "white",
    padding: 10,
  },
  saleLayout: {
    width: 100,
    textAlign: "center",
    margin: "0 auto",
  },
  saleContainer: {
    position: "relative",
    fontSize: 14,
    backgroundColor: "#3685B5",
    color: "white",
    padding: 12,
    display: "inline-block",
    margin: 10,
    marginBottom: 10,
  },
  normalPrice: {
    fontSize: 18,
    textDecoration: "line-through",
    display: "inline-block",
  },
  salePrice: {
    color: "#222",
    fontSize: 22,
    fontWeight: 800,
    display: "inline-block",
    marginLeft: 10,
  },
  newContainer: {
    position: "relative",
    fontSize: 13,
     backgroundColor: "#454545", // black friday color
  //  backgroundColor: "#bd0421", // christmas / valentines day
    color: "white",
    padding: 5,
    display: "inline-block",
    marginLeft: 6
  },
}));

function ProductDetails(props) {
  let { inventory, product, id } = props;
  const [open, setOpen] = React.useState(false);
  const [noMoreItemsModal, setNoMoreItemsModal] = React.useState(false);
  let incrementCart = props.incrementCart;
  let storedItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const [cartValue, setCartValue] = useState(storedItems);
  const classes = useStyles();
  const [hideLoader, setHideLoader] = useState(false);
  const [amountAvailable, setAmountAvailable] = useState(0);
  let imageLink;
  let updatedAmountAvailable = 0;
  let amountInCart = 0;
  let alreadyInCart = false;

  useEffect(() => {
    if (product) {
      setTimeout(() => {
        setAmountAvailable(product.amountAvailable);
        setHideLoader(true);
      }, 1000);
    }
  }, [product]);

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this item",
          url: window.location.href,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      // fallback
      console.log("not supported");
    }
  };

  if (inventory) {
    let domain = "https://durbancomics.com/products/";
    imageLink = domain + product.code + ".png";
  }

  const updateCartItemCount = (cartItems) => {
    cartItems.forEach((i) => {
      if (i.name === product.name) {
        i.count++;
        alreadyInCart = true;
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseNoMoreItems = () => {
    setNoMoreItemsModal(false);
  };

  const addToCart = () => {
    let counter = Number(localStorage.getItem("cartCounter"));
    const cartItems = cartValue.slice();
    let cartItem = cartItems.filter((i) => i.name === product.name);

    if (cartItem.length > 0) {
      amountInCart = cartItem[0].count || 0;
    } else amountInCart = 0;

    if (amountInCart > 0) alreadyInCart = true;

    if (amountAvailable - amountInCart > 0) {
      updatedAmountAvailable = amountAvailable - amountInCart; //decrease amount available
      setAmountAvailable(updatedAmountAvailable);
      updateCartItemCount(cartItems);

      if (!alreadyInCart) {
        cartItems.push({ ...product, count: 1, id: id });
      }

      setCartValue(cartItems);
      counter++;
      localStorage.setItem("cartCounter", counter);
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      incrementCart(counter, cartItems);
      setOpen(true);
    } else {
      setNoMoreItemsModal(true);
    }
  };

  return !hideLoader ? (
    <div className={classes.overlay}>
      <PageLoader />
    </div>
  ) : (
    <div className={classes.root}>
      <Card className={classes.cardRoot} elevation={0}>
        <Divider />
        <CardMedia
          className={classes.image}
          image={imageLink}
          title={product.name}
        />
        <Divider />
        <CardContent className={classes.CardContent}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.name}
          >
            {product.name}
          </Typography>

          { product && product.new ? (
        <div className={classes.newContainer}>NEW</div>
        ): null}


          {product.sale ? (
            <div className={classes.saleLayout}>
              <div className={classes.saleContainer}>ON SALE</div>
              <div className={classes.normalPrice}>R{product.price}</div>
              <div className={classes.salePrice}>R{product.salePrice}</div>
            </div>
          ) : (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.price}
            >
              R{product.price}
            </Typography>
          )}

          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.description}
          >
            {product.description}
          </Typography>
          <Divider />

          <Grid container justify="center" style={{ marginTop: "20px" }}>
            <Button
              className={classes.reset}
              variant="contained"
              style={{ textTransform: "none", backgroundColor: "#E5A718" }}
              component={Link}
              to="../"
            >
              Back
            </Button>

            <Button
              className={classes.addToCart}
              variant="contained"
              style={{ textTransform: "none", backgroundColor: "#3685B5" }}
              onClick={addToCart}
            >
              Add to Cart
            </Button>

            {navigator.share ? (
              <ShareIcon
                className={classes.share}
                variant="contained"
                onClick={handleShare}
              />
            ) : null}
          </Grid>
        </CardContent>
      </Card>

      <div className={classes.fakeFooter} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modal}>
          <Grid container justify="center" style={{ marginTop: "0px" }}>
            <img src={success} alt="Order Added" width="100px" height="100px" />
            <div className={classes.modalText}>
              Item has been added to cart.
            </div>

            <Button
              className={classes.modalButton2}
              variant="contained"
              style={{ textTransform: "none", backgroundColor: "#3685B5" }}
              component={Link}
              to="../cart"
            >
              Go to Cart
            </Button>

            <Button
              className={classes.modalButton}
              variant="contained"
              style={{ textTransform: "none", backgroundColor: "#E5A718" }}
              onClick={handleClose}
            >
              Continue Shopping
            </Button>
          </Grid>
        </div>
      </Modal>

      <Modal
        open={noMoreItemsModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modal}>
          <Grid container justify="center" style={{ marginTop: "0px" }}>
            <img
              src={sad}
              alt="Order Has not been added"
              width="100px"
              height="100px"
            />
            <div className={classes.modalText}>
              Sorry unfortunately there are no more of these items available.
            </div>

            <Button
              className={classes.modalButton2}
              variant="contained"
              style={{ textTransform: "none", backgroundColor: "#3685B5" }}
              component={Link}
              to="../cart"
            >
              Go to Cart
            </Button>
            <Button
              className={classes.modalButton}
              variant="contained"
              style={{ textTransform: "none", backgroundColor: "#E5A718" }}
              onClick={handleCloseNoMoreItems}
            >
              Continue Shopping
            </Button>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  incrementCart: (counter, items) => {
    dispatch(incrementCartItems(counter, items));
  },
});

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const inventory = state.firestore.data.inventory;
  const product = inventory ? inventory[id] : null;

  return {
    product: product,
    inventory: inventory,
    cart: state.cart,
    id: id,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: "inventory" }])
)(ProductDetails);
