import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../logo2.png";
import play from "../play.png";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: 20
  },
  cardRoot: {
    width: "90%",
    margin: "0 auto",
    marginTop: 40
  },
  heading: {
    fontSize: 16,
    fontWeight: 700,
    color: "#E5A718"
  },
  faq: {
    width: "90%",
    margin: "0 auto",
    marginTop: 20,
    marginBottom: 20
  },
  download: {
    width: "90%",
    margin: "0 auto",
    marginTop: 10,
    marginBottom: 100
  },
  logo: {
    width: 300,
    height: 300,
    display: "inline-block",
    [theme.breakpoints.down("md")]: {
      width: "50%",
      height: "50%",
      marginLeft: "25%"
    }
  },
  version: {
    color: "gray",
    fontSize: 10,
    textAlign: "center",
    padding: 20
  },
  badge: {
   width:250
  }
}));

const About = () => {
  const classes = useStyles();
  const handleClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.durbancomics.app&hl=en_ZA&gl=US', '_blank')
  }


  return (
    <div className={classes.root}>
      <Card className={classes.cardRoot}>
        <CardContent className={classes.CardContent}>
          <div className={classes.version}> V 2.11 </div>
          <img src={logo} className={classes.logo} alt="logo" />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.heading}
          >
            Banking Details
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Durban Comics (PTY) LTD
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Account Number: 62742090070
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Bank: FNB
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Type: Cheque
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Branch: La Lucia
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
            Please use your name as reference.
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.faq}>
        <CardContent className={classes.CardContent}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.heading}
          >
            Quick Info
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            We are online only but courier all over South Africa.
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            You can pay via EFT or via the store or ask us for a payment link.
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Delivery takes 3-5 working days.
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.faq}>
        <CardContent className={classes.CardContent}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.heading}
          >
           Returns Policy
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
           In the case that a product needs to be replaced please email us on durbancomics@gmail.com to log a return.
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            A broken item can be replaced no later than 7 days of the customer receiving the product.
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            For more details please see the <a target="_blank" href="https://www.gov.za/documents/consumer-protection-act"> Consumer Protection Act of South Africa.</a>
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.download}>
        <CardContent className={classes.CardContent}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.heading}
          >
            Download our App
          </Typography>
         

          <img
            className={classes.badge}
            src={play}
            alt="logo"
            onClick={handleClick}
          />

        </CardContent>
      </Card>





    </div>
  );
};

export default About;
