import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFirebase } from "react-redux-firebase";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  logout: {
    fontSize: 15,
    color: "white",
    fontWeight: 700,
    backgroundColor: "#E5A718"
  }
}));

const LogoutButton = props => {
  const classes = useStyles();
  const firebase = useFirebase();

  const signOut = () => {
    firebase.logout().then(res => {
      window.location.href = "../";
    });
  };

  return (
    <Grid container justify="center" style={{ marginTop: "30px" }}>
      <Button
        className={classes.logout}
        variant="contained"
        style={{ textTransform: "none" }}
        onClick={signOut}
      >
        Logout
      </Button>
    </Grid>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

export default connect(mapStateToProps)(LogoutButton);
