import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import { useFirestore } from "react-redux-firebase";
import PageLoader from "../../layout/PageLoader";
import logo from "../../logo2.png";
const useStyles = makeStyles(theme => ({
  root: {},
  form: {
    justifyContent: "center",
    textAlign: "center",
    margin: "0 auto",
    padding: 10
  },
  loading: {
    color: theme.palette.text.primary,
    margin: 0
  },
  pageLoaderWrapper: {
    marginTop: -350
  }
}));

const Checkout = props => {
  const classes = useStyles();
  const firestore = useFirestore();

  const [showLoaderMessage, setShowLoaderMessage] = useState(false);
  let logs = [];

  useEffect(() => {}, [showLoaderMessage]);

  const handleSubmit = e => {
    e.preventDefault();

    var yoco = new window.YocoSDK({
      // publicKey: "pk_test_69eb7ec97y34Xo98f174"
      publicKey: "pk_live_733ca8587y34Xo97d094"
    });

    const costInCents = props.totalCost * 100;

    //add data to db
    //check if data added correctly
    // if added then show yoco
   
    yoco.showPopup({
      amountInCents: costInCents,
      currency: "ZAR",
      customer: { email: props.auth.email, firstName: props.auth.email },
      name: "Durban Comics",
      description: "Awesome description",
      image: logo,
      callback: function(result) {
        // This function returns a token that your server can use to capture a payment
        setShowLoaderMessage(true);
        if (result.error) {
          const errorMessage = result.error.message;
          alert("error occured: " + errorMessage);
        } else {
          // alert("card successfully tokenised: " + result.id);
          checkPayment(result.id, costInCents);
          //save result.id to the order as proof of payment
        }
      }
    });
  };

  const checkPayment = async (token, amount) => {
    let url =
      "https://durbancomics.com/charge.php?token=" +
      token +
      "&amount=" +
      amount;

    let response = await fetch(url);
    response = await response.json();

    if (response.status === "successful") {
      setTimeout(() => {
        updateDatabase(response.id);
      }, 1500);

      setTimeout(() => {
        setShowLoaderMessage(false);
        console.log("Success Redirect to confirmation screen");
        window.location = "../paymentSuccess";
      }, 3000);
    } else {
      window.location = "../paymentError";
    }
  };

  const updateDatabase = token => {
    let orderForm = props.cartItems;
    let checkoutBasket = [];
    let updatedInventory = [];
    orderForm.forEach(item => {
      let count = item.count;
      let price = 0;

      item.sale ? (price = item.salePrice) : (price = item.price);

      updatedInventory.push({
        name: item.name,
        count: item.count,
        price: price
      });
      for (var i = 0; i < count; i++) {
        checkoutBasket.push({ name: item.name, price: item.price });
      }
    });

    updateInventory(updatedInventory);
    addOrder(
      {
        email: props.auth.email,
        items: updatedInventory,
        totalCost: props.totalCost,
        totalPaid: props.totalCost
      },
      token
    );
  };

  const updateInventory = products => {
    const currentDate = Date.now();
    products.forEach(item => {
      firestore
        .collection("inventory")
        .where("name", "==", item.name)
        .get()
        .then(snapshot => {
          snapshot.docs.forEach(product => {
            let inventoryItem = { ...product.data(), ["id"]: product.id };
            inventoryItem.amountAvailable =
              inventoryItem.amountAvailable - item.count;

            logs = inventoryItem.logs || [];
            logs.push({
              currentDate: currentDate,
              action: "sold",
              amount: item.count,
              amountAvailable: inventoryItem.amountAvailable
            });

            firestore
              .collection("inventory")
              .doc(product.id)
              .update({
                amountAvailable: inventoryItem.amountAvailable,
                logs: logs
              })
              .catch(err => {
                alert("An error occurred please contact Durban Comics Support");
              });
          });
        })
        .catch(err => {
          alert("An error occurred please contact Durban Comics Support");
        });
    });
  };

  const addOrder = async (order, token) => {
    const currentDate = Date.now();
    let transactionLog = [];
    let paymentHistory = [];

    if (token) {
      transactionLog.push({ date: currentDate, action: "order added" });
      transactionLog.push({ date: currentDate, action: "payment made" });
      paymentHistory = [{ payment: order.totalPaid, date: currentDate }];
      transactionLog.push({ date: currentDate, action: "payment complete" });

      await firestore
        .collection("orders")
        .add({
          ...order,
          courierCompany: "",
          courierFee: 0,
          discount: 0,
          history: transactionLog,
          isCancelled: false,
          isComplete: false,
          isCouriered: false,
          isPaid: true,
          isWebsiteOrder: true,
          payments: paymentHistory,
          totalOwing: 0,
          trackingNumber: ""
        })
        .then(docRef => {
          firestore.collection("payments").add({
            orderId: docRef.id,
            email: order.email,
            items: order.items,
            date: currentDate,
            payment: order.totalPaid,
            token: token
          });
        })
        .catch(err => {
          console.log("error---- " + err);
          alert("An error occurred please contact Durban Comics Support");
        });
    }
  };

  return (
    <div className={classes.root}>
      <form id="addOrderForm" className={classes.form} onSubmit={handleSubmit}>
        {!showLoaderMessage ? (
          <Button
            className={classes.proceed}
            variant="contained"
            style={{
              textTransform: "none",
              backgroundColor: "#1CB5E0",
              color: "white"
            }}
            type="submit"
          >
            Proceed to checkout
          </Button>
        ) : null}

        {showLoaderMessage ? (
          <div className={classes.loading}>
            Validating payment. Please Wait....
            <div className={classes.pageLoaderWrapper}>
              <PageLoader />
            </div>
          </div>
        ) : null}
      </form>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    inventory: state.firebase.inventory,
    orders: state.firebase.orders
  };
};

export default compose(connect(mapStateToProps))(Checkout);
