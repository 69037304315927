import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFirebase } from "react-redux-firebase";
import { Typography, Grid, TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import { useFirestore } from "react-redux-firebase";
import { Link } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  root: {
    background:
      "radial-gradient(circle, rgba(92,171,217,1) 0%, rgba(54,133,181,1) 49%, rgba(3,64,102,1) 100%)",
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  container: {
    margin: "0 auto",
    width: 250
  },

  header: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: 700,
    color: "white",
    margin: "0 auto",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20
    }
  },
  text: {
    padding: 5,
    margin: "0 auto",
    textAlign: "center",
    marginBottom: 5,
    background: "white",
    borderRadius: 25
  },
  message: {
    textAlign: "center",
    color: "red",
    fontSize: 14
  },

  back: {
    marginLeft: 20,
    width: 100,
    fontSize: 14,
    fontWeight: 600,
    color: "white"
  },

  register: {
    width: 100,
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: "#E5A718",
    color: "white"
  },

  finePrint: {
    margin: "0 auto",
    marginTop: "10px",
    textAlign: "center"
  },
  finePrintText: {
    fontSize: 14,
    textDecoration: "none",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: 10
    }
  },
  input: {
    "&::placeholder": {
      textAlign: "center"
    }
  }
}));

const Register = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    address: "",
    suburb: "",
    city: "",
    code: "",
    phone: ""
  });

  const handleChange = e => {
    const { id, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleSubmitClick = e => {
    e.preventDefault();
    reg();
  };

  const reg = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(state.email, state.password)
      .then(userCredential => {
        firestore
          .collection("users")
          .doc(userCredential.user.uid)
          .set({
            name: state.name,
            email: state.email,
            address: state.address,
            suburb: state.suburb,
            city: state.city,
            code: state.code,
            phone: state.phone
          })
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch(error => {
            console.error("Error writing document: ", error);
          });
      })
      .catch(error => {
        var errorMessage = error.message;
        console.log(errorMessage);
        setErrorMessage(errorMessage);
      });
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.header}> REGISTER </Typography>

      <div className={classes.container}>
        <TextField
          className={classes.text}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input }
          }}
          InputLabelProps={{
            required: false
          }}
          id="name"
          placeholder="Name"
          type="name"
          fullWidth
          required
          onChange={handleChange}
        />

        <TextField
          className={classes.text}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input }
          }}
          InputLabelProps={{ required: false }}
          id="email"
          placeholder="Email"
          type="email"
          fullWidth
          required
          onChange={handleChange}
        />

        <TextField
          className={classes.text}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input }
          }}
          InputLabelProps={{
            required: false
          }}
          id="password"
          placeholder="Password"
          type="password"
          fullWidth
          required
          onChange={handleChange}
        />

        <TextField
          className={classes.text}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input }
          }}
          InputLabelProps={{
            required: false
          }}
          id="address"
          placeholder="Address"
          type="address"
          fullWidth
          required
          onChange={handleChange}
        />

        <TextField
          className={classes.text}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input }
          }}
          InputLabelProps={{
            required: false
          }}
          id="suburb"
          placeholder="Suburb"
          type="suburb"
          fullWidth
          required
          onChange={handleChange}
        />

        <TextField
          className={classes.text}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input }
          }}
          InputLabelProps={{
            required: false
          }}
          id="city"
          placeholder="City"
          type="city"
          fullWidth
          required
          onChange={handleChange}
        />

        <TextField
          className={classes.text}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input }
          }}
          InputLabelProps={{
            required: false
          }}
          id="code"
          placeholder="Code"
          type="code"
          fullWidth
          required
          onChange={handleChange}
        />

        <TextField
          className={classes.text}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input }
          }}
          InputLabelProps={{
            required: false
          }}
          id="phone"
          placeholder="Phone"
          type="phone"
          fullWidth
          required
          onChange={handleChange}
        />
      </div>

      <Grid container justify="center" style={{ marginTop: 20 }}>
        <Button
          className={classes.register}
          variant="contained"
          style={{ textTransform: "none" }}
          onClick={handleSubmitClick}
        >
          Register
        </Button>

        <Button
          className={classes.back}
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
          component={Link}
          to="../login"
        >
          Back
        </Button>
      </Grid>

      <Typography className={classes.finePrint}>
        <Link to="../" className={classes.finePrintText}>
          Back to Shop
        </Link>
      </Typography>

      <div className={classes.message}>
        {errorMessage ? <p> {errorMessage}</p> : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

export default compose(connect(mapStateToProps))(Register);
