import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import necklace from "../../necklace.png";
import bracelet from "../../bracelet.png";
import earrings from "../../earrings.png";
import keyrings from "../../keyrings.png";
import funko from "../../funko.png";
import cg from "../../cableguy.png";
import lighter from "../../lighter.png";
import mystery from "../../mystery.png";
import men from "../../men.png";
import ladies from "../../ladies.png";
import logo from "../../logo2.png";
import sale from "../../sale.png"; 
import newicon from "../../new.png"; 
//import sale from "../../redsale.png";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
const mobile = 700;
const desktop = 1023;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "0 auto",
    marginTop: 15,
    width: "92vw",
    height: 100,
    overflow: "hidden",
    backgroundColor: "#fff",
    [theme.breakpoints.up(desktop)]: {
      width: "100vw",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  main1: {
    margin: "0 auto",
    width: "90vw",
    height: 120,
    whiteSpace: "nowrap",
    overflowX: "scroll",
    textAlign: "center",
  },

  item: {
    padding: 5,
    marginLeft: 8,
    marginRight: 8,
    display: "inline-block",
  },

  icon: {
    width: 60,
    height: 60,
  },

  name: {
    textAlign: "center",
    marginBottom: 0,
    color: "#2f2f2f",
  },

  arrowRight: {
    width: 20,
    height: 80,
    float: "right",
  },

  backArrow: {
    marginRight: 100,
  },

  overlayOffArrowRight: {
    position: "absolute",
    width: 18,
    height: 80,
    marginTop: "-80px",
    float: "right",
    [theme.breakpoints.up(desktop)]: {
      display: "none",
    },
  },

  overlayArrowLeft: {
    position: "absolute",
    width: 18,
    height: 80,
    marginTop: 46,
    float: "left",
    [theme.breakpoints.up(desktop)]: {
      display: "none",
    },
  },

  svg_icons: {
    color: "#E5A718",
    transform: "scale(1.8)",
  },
}));

const CategoryScroller = ({ filterProducts }) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const [hoverBack, setHoverBack] = useState(false);
  const handleChange = (e) => {
    filterProducts(e);
  };

  const handleMouseOverBack = () => {
    setHoverBack(true);
  };

  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseExit = () => {
    setHover(false);
  };

  const handleMouseExitBack = () => {
    setHoverBack(false);
  };

  function scroll() {
    var elmnt = document.getElementById("wrapper");
    elmnt.scrollBy(100, 0);
  }

  function scrollBack() {
    var elmnt = document.getElementById("wrapper");
    elmnt.scrollBy(-100, 0);
  }

  return (
    <div id="wrapper" className={classes.wrapper}>
      <div
        className={classes.backArrow}
        onMouseOver={handleMouseOverBack}
        onMouseLeave={handleMouseExitBack}
        onClick={scrollBack}
      >
        <div className={classes.overlayArrowLeft}>
          <ArrowLeftIcon className={classes.svg_icons} />
        </div>
      </div>

      <div id="main1" className={classes.main1}>
        <div className={classes.item}>
          <img
            src={logo}
            className={classes.icon}
            alt="logo"
            onClick={() => handleChange("")}
          />
          <div className={classes.name} value="">
            All
          </div>
        </div>

        <div className={classes.item}>
          <img
            src={newicon}
            className={classes.icon}
            alt="logo"
            onClick={() => handleChange("New")}
          />
          <div className={classes.name} value="New">
            New
          </div>
        </div>


        <div className={classes.item}>
          <img
            src={sale}
            className={classes.icon}
            alt="logo"
            onClick={() => handleChange("Sale")}
          />
          <div className={classes.name} value="Sale">
            Sale
          </div>
        </div>

        <div className={classes.item}>
          <img
            src={mystery}
            className={classes.icon}
            alt="logo"
            onClick={() => handleChange("Mystery")}
          />
          <div className={classes.name} value="Mystery">
            Mystery Boxes
          </div>
        </div>

        <div className={classes.item}>
          <img
            src={ladies}
            className={classes.icon}
            alt="logo"
            onClick={() => handleChange("Ladies")}
          />
          <div className={classes.name} value="Ladies">
            For Her
          </div>
        </div>

        <div className={classes.item}>
          <img
            src={men}
            className={classes.icon}
            alt="logo"
            onClick={() => handleChange("Men")}
          />
          <div className={classes.name} value="Men">
            For Him
          </div>
        </div>

        <div className={classes.item}>
          <img
            src={funko}
            className={classes.icon}
            alt="logo"
            onClick={() => handleChange("Funko")}
          />
          <div className={classes.name} value="Funko">
            Funko Pops
          </div>
        </div>

        <div className={classes.item}>
          <img
            src={keyrings}
            className={classes.icon}
            alt="logo"
            onClick={() => handleChange("Keyring")}
          />
          <div className={classes.name} value="Keyring">
            Keyrings
          </div>
        </div>

        <div className={classes.item} onClick={() => handleChange("Necklace")}>
          <img src={necklace} className={classes.icon} alt="logo" />
          <div className={classes.name} value="Necklace">
            Necklaces
          </div>
        </div>

        <div className={classes.item}>
          <img
            src={bracelet}
            className={classes.icon}
            alt="logo"
            onClick={() => handleChange("Bracelet")}
          />
          <div className={classes.name} value="Bracelet">
            Bracelets
          </div>
        </div>

        <div className={classes.item}>
          <img
            src={earrings}
            className={classes.icon}
            alt="logo"
            onClick={() => handleChange("Earring")}
          />
          <div className={classes.name} value="Earrings">
            Earrings
          </div>
        </div>

        <div className={classes.item}>
          <img
            src={cg}
            className={classes.icon}
            alt="logo"
            onClick={() => handleChange("Cable Guy")}
          />
          <div className={classes.name} value="Cable Guy">
            Cable Guy
          </div>
        </div>

        <div className={classes.item}>
          <img
            src={lighter}
            className={classes.icon}
            alt="logo"
            onClick={() => handleChange("Lighter")}
          />
          <div className={classes.name} value="Lighter">
            Lighters
          </div>
        </div>
      </div>

      <div
        className={classes.arrowRight}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseExit}
        onClick={scroll}
      >
        <div className={classes.overlayOffArrowRight}>
          <ArrowRightIcon className={classes.svg_icons} />
        </div>
      </div>
    </div>
  );
};

export default CategoryScroller;
