import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    margin: 10
  },
  paper: {
    padding: theme.spacing(1),
    margin: "auto",
    maxWidth: 500
  },
  name: {
    fontSize: 12
  },
  quantity: {
    fontSize: 12
  },
  image: {
    width: 64,
    height: 64
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%"
  },
  details: {
    marginLeft: 10
  }
}));

export default function CartItem(props) {
  const classes = useStyles();
  let imagelink =
    "http://durbancomics.com/products/" + props.item.code + ".png";
  let route = "../product/" + props.item.id;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item>
              <ButtonBase className={classes.image}>
                <Link to={route}>
                  <img className={classes.img} alt="complex" src={imagelink} />
                </Link>
              </ButtonBase>
            </Grid>

            <Grid
              item
              xs
              container
              direction="column"
              spacing={2}
              className={classes.details}
            >
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  className={classes.name}
                >
                  {props.item.name}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.quantity}
                >
                  Quantity: {props.item.count}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                {props.item.sale ? (
                  <div>R{props.item.salePrice * props.item.count}</div>
                ) : (
                  <div>R{props.item.price * props.item.count}</div>
                )}
              </Typography>

              <IconButton
                aria-label="delete"
                onClick={() => props.remove(props.item)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
