const initState = {
  orders: []
};

const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_ORDER": {
      console.log("created ORDER" + action);
      localStorage.removeItem("cartItems");
      return state;
    }
    case "CREATE_ORDER_ERROR": {
      console.log("create ORDER ERROR" + action.ERROR);
      return state;
    }
    case "DELETE_ORDER_DONE": {
      console.log("DELETED ORDER" + action);
      return state;
    }
    case "DELETE_ORDER_ERROR": {
      console.log("DELETE ORDER ERROR" + action.ERROR);
      return state;
    }
    default:
      return state;
  }
};

export default orderReducer;
