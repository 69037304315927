import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  icon: {
    width: 100,
    margin: "0 auto"
  }
}));

const PageLoader = () => {
  const classes = useStyles();

  return (
    <div id="pageLoader" className={classes.root}>
      <CircularProgress className={classes.icon} />
    </div>
  );
};
export default PageLoader;
