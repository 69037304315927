export const incrementCartItems = (counter, items) => ({
  type: "INCREMENT_CART_ITEMS",
  counter: counter,
  items: items
});

export const updateCartItems = items => ({
  type: "UPDATE_CART_ITEMS",
  items: items
});
