import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import cover from "../cover.png";
import cover2 from "../cover2.png";
import cover3 from "../cover3.png";
import cover4 from "../cover4.png";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

const useStyles = makeStyles((theme) => ({
  carousel: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: "100%",
      margin: "0 auto",
      marginTop: 20,
    },
  },
}));

const HomeCarousel = ({ settings }) => {
  const classes = useStyles();
  return (
    <Carousel
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      autoPlay={true}
      interval={3500}
      infiniteLoop={true}
      transitionTime={500}
    >
      <div className={classes.carousel}>
        <img alt="banner" src={cover} />
      </div>

      <div className={classes.carousel}>
        <img alt="banner" src={cover2} />
      </div>

      <div className={classes.carousel}>
        <img alt="banner" src={cover3} />
      </div>
      <a
        href="https://play.google.com/store/apps/details?id=com.durbancomics.app&hl=en_ZA&gl=US"
        target="_blank"
        rel="noreferrer"
      >
        <div className={classes.carousel}>
          <img alt="banner" src={cover4} />
        </div>
      </a>
    </Carousel>
  );
};

const mapStateToProps = (state) => {
  return {
    settings: state.firestore.ordered.settings,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "settings" }])
)(HomeCarousel);
