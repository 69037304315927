import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../logo.png";
const useStyles = makeStyles(theme => ({
  root: {
   // background:
    //  "radial-gradient(circle, rgba(92,171,217,1) 0%, rgba(54,133,181,1) 49%, rgba(3,64,102,1) 100%)",

    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  logo: {
    width: 231,
    margin: "0 auto"
  },
  icon: {
    width: 100,
    margin: "0 auto",
    marginTop: 40,
    color: "white"
  }
}));

const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={logo} className={classes.logo} alt="logo" />
    </div>
  );
};
export default LoadingScreen;
