import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { incrementCartItems } from "../../store/actions/cartActions";
import { connect } from "react-redux";
import { compose } from "redux";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  header: {
    fontSize: 40,
    fontWeight: 700,
    margin: "0 auto",
    padding: 10,
    color: "#F5F5F5",
    [theme.breakpoints.down("sm")]: {
      fontSize: 30
    },
    textAlign: "center"
  },
  sub: {
    fontSize: 15,
    fontWeight: 400,
    margin: "0 auto",
    padding: 10,
    color: "#F5F5F5",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15
    },
    textAlign: "center"
  },
  card: {
    background: "#63AB62",
    width: 300,
    margin: "0 auto",
    marginTop: -50,
    padding: 30,
    borderRadius: 10,
    textAlign: "center"
  },

  icon: {
    fontSize: 100,
    margin: "0 auto",
    color: "white"
  }
}));

const PaymentSuccess = ({ updateCart }) => {
  const classes = useStyles();
  localStorage.removeItem("cartItems");
  localStorage.removeItem("cartCounter");
  updateCart(0, []);

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <CheckCircleOutlineIcon className={classes.icon} />
        <Typography className={classes.header}>Success</Typography>
        <Typography className={classes.sub}>
          Your purchase is complete
        </Typography>

        <Grid container justify="center" style={{ marginTop: "30px" }}>
          <Button
            className={classes.reset}
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            component={Link}
            to="../"
          >
            Continue Shopping
          </Button>
        </Grid>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  updateCart: (counter, items) => {
    dispatch(incrementCartItems(counter, items));
  }
});

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(PaymentSuccess);
