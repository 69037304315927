import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import logo from "../logo.png";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "#fff",
    boxShadow: "none",
  },
  icon: {
    color: "#414141",
    marginLeft: "auto",
  },
  counter: {
    backgroundColor: "#E5A718",
    borderRadius: 20,
    width: 18,
    height: 18,
    position: "absolute",
    top: 8,
    right: 20,
  },
  counterText: {
    textAlign: "center",
    fontSize: 13,
    fontweight: 700,
  },
}));

const TopBar = ({ cartCounter, updateShowFilter }) => {
  const classes = useStyles();
  let showCounter = false;
  let counterValue = cartCounter || 0;
  if (cartCounter > 0) showCounter = true;

  const handleClickSearch = (e) => {
    e.preventDefault();
    updateShowFilter();
  };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <img src={logo} alt="logo" height="30" width="180" />
        <div className={classes.icon}>
          <IconButton onClick={handleClickSearch} color="inherit">
            <SearchIcon />
          </IconButton>
          <IconButton
            aria-label="show"
            component={Link}
            to="../cart"
            color="inherit"
          >
            <ShoppingCartIcon />
          </IconButton>
        </div>
      </Toolbar>

      {showCounter ? (
        <div id="counter" className={classes.counter}>
          <div className={classes.counterText}>{counterValue}</div>
        </div>
      ) : null}
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    cartCounter: state.cart.counter,
  };
};

export default compose(connect(mapStateToProps))(TopBar);
