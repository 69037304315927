import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, TextField, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import { useFirestore } from "react-redux-firebase";
import { Link } from "react-router-dom";
import PageLoader from "../../layout/PageLoader";
const useStyles = makeStyles(theme => ({
  root: {
    background:
      "radial-gradient(circle, rgba(92,171,217,1) 0%, rgba(54,133,181,1) 49%, rgba(3,64,102,1) 100%)",
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "-48px"
  },
  container: {
    margin: "0 auto",
    width: 250
  },
  header: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: 700,
    color: "white",
    margin: "0 auto",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20
    }
  },
  text: {
    padding: 5,
    margin: "0 auto",
    textAlign: "center",
    marginBottom: 5,
    background: "white",
    borderRadius: 25
  },
  message: {
    textAlign: "center",
    color: "red",
    fontSize: 14
  },
  back: {
    marginLeft: 20,
    width: 100,
    fontSize: 14,
    fontWeight: 600,
    color: "white"
  },
  update: {
    width: 100,
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: "#E5A718",
    color: "white"
  },
  input: {
    "&::placeholder": {
      textAlign: "center"
    }
  }
}));

const UpdatePersonalDetails = ({ auth }) => {
  const classes = useStyles();
  const firestore = useFirestore();
  let userDetails = {};
  const [hideLoader, setHideLoader] = useState(false);
  const [name, setName] = useState();
  const [address, setAddress] = useState();
  const [suburb, setSuburb] = useState(userDetails.suburb);
  const [code, setCode] = useState(userDetails.code);
  const [city, setCity] = useState(userDetails.city);
  const [phone, setPhone] = useState(userDetails.phone);

  useEffect(() => {
    firestore
      .collection("users")
      .doc(auth.uid)
      .get({
        name: name,
        address: address,
        suburb: suburb,
        city: city,
        code: code,
        phone: phone
      })
      .then(doc => {
        if (doc.exists) {
          userDetails = doc.data();
          setHideLoader(true);
          setName(userDetails.name);
          setAddress(userDetails.address);
          setSuburb(userDetails.suburb);
          setCity(userDetails.city);
          setCode(userDetails.code);
          setPhone(userDetails.phone);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(err => {
        console.log("error---- " + err);
      });
  }, []);

  const handleChange = e => {
    switch (e.target.id) {
      case "name":
        setName(e.target.value);
        break;
      case "address":
        setAddress(e.target.value);
        break;
      case "suburb":
        setSuburb(e.target.value);
        break;
      case "city":
        setCity(e.target.value);
        break;
      case "code":
        setCode(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
      default:
        break;
    }
  };

  const updateDatabase = () => {
    firestore
      .collection("users")
      .doc(auth.uid)
      .update({
        name: name,
        address: address,
        suburb: suburb,
        city: city,
        code: code,
        phone: phone
      })
      .then(docRef => {
        window.location = "../profile";
      })
      .catch(err => {
        console.log("error---- " + err);
      });
  };

  const handleSubmitClick = e => {
    e.preventDefault();

    if (!name || !address || !suburb || !city || !code || !phone) {
      alert("Please ensure all fields have been updated");
    } else {
      updateDatabase();
    }
  };

  return !hideLoader ? (
    <div className={classes.overlay}>
      <PageLoader />
    </div>
  ) : (
    <div className={classes.root}>
      <Typography className={classes.header}> Update Details </Typography>
      {userDetails ? (
        <div className={classes.container}>
          <TextField
            className={classes.text}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.input }
            }}
            InputLabelProps={{
              required: false
            }}
            id="name"
            placeholder={name}
            type="name"
            fullWidth
            required={true}
            onChange={handleChange}
          />

          <TextField
            className={classes.text}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.input }
            }}
            InputLabelProps={{
              required: false
            }}
            id="address"
            placeholder={address}
            type="address"
            fullWidth
            required={true}
            onChange={handleChange}
          />
          <TextField
            className={classes.text}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.input }
            }}
            InputLabelProps={{
              required: false
            }}
            id="suburb"
            placeholder={suburb}
            type="suburb"
            fullWidth
            required={true}
            onChange={handleChange}
          />
          <TextField
            className={classes.text}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.input }
            }}
            InputLabelProps={{
              required: false
            }}
            id="city"
            placeholder={city}
            type="city"
            fullWidth
            required={true}
            onChange={handleChange}
          />
          <TextField
            className={classes.text}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.input }
            }}
            InputLabelProps={{
              required: false
            }}
            id="code"
            placeholder={code}
            type="code"
            fullWidth
            required={true}
            onChange={handleChange}
          />
          <TextField
            className={classes.text}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.input }
            }}
            InputLabelProps={{
              required: false
            }}
            id="phone"
            placeholder={phone}
            type="phone"
            fullWidth
            required={true}
            onChange={handleChange}
          />
        </div>
      ) : null}
      <Grid container justify="center" style={{ marginTop: 20 }}>
        <Button
          className={classes.update}
          variant="contained"
          style={{ textTransform: "none" }}
          onClick={handleSubmitClick}
        >
          Update
        </Button>
        <Button
          className={classes.back}
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
          component={Link}
          to="../profile"
        >
          Back
        </Button>
      </Grid>

    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

export default compose(connect(mapStateToProps))(UpdatePersonalDetails);
