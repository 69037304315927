import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Switch, Route, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Home from "../pages/Home/Home";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import ResetPassword from "../pages/Auth/ResetPassword";
import About from "../pages/About";
import Profile from "../pages/Profile/Profile";
import Orders from "../pages/Orders/Orders";
import PaymentSuccess from "../pages/Cart/PaymentSuccess";
import PaymentError from "../pages/Cart/PaymentError";
import LoadingScreen from "../pages/LoadingScreen";
import TopBar from "./TopBar";
import Footer from "../layout/Footer";
import Cart from "../pages/Cart/Cart";
import { incrementCartItems } from "../store/actions/cartActions";
import ProductDetails from "../pages/ProductDetails/ProductDetails";
import UpdatePersonalDetails from "../pages/Profile/UpdatePersonalDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    marginTop: 30,
    marginLeft: 0,
  },
  noHeaderAndFooter: {
    flexGrow: 1,
    padding: theme.spacing(0),
    marginTop: 0,
    marginLeft: 0,
  },
}));

function AppContainer({ auth, incrementCart }) {
  const classes = useStyles();
  let location = useLocation();
  let counter = Number(localStorage.getItem("cartCounter"));
  let storedItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  incrementCart(counter, storedItems);
  const [showApp, setShowApp] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    if (!auth.isLoaded) {
      setTimeout(() => {
        setShowApp(true);
      }, 1000);
    }
  }, [auth]);

  if (!showApp) {
    return <LoadingScreen />;
  }

  const updateShowFilter = () => {
    setShowFilter(!showFilter);
  };

  let showHeaderAndFooter = false;

  if (
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    location.pathname === "/resetPassword"
  ) {
    showHeaderAndFooter = false;
  } else {
    showHeaderAndFooter = true;
  }

  return auth.uid ? (
    <div className={classes.root}>
      <CssBaseline />
      <TopBar updateShowFilter={updateShowFilter}></TopBar>

      <main className={classes.content}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <Home {...props} showFilter={showFilter} updateShowFilter={updateShowFilter} />}
          />
          <Route path="/login" component={Home} />
          <Route path="/register" component={Home} />
          <Route path="/product/:id" component={ProductDetails} />
          <Route path="/cart" component={Cart} />
          <Route path="/about" component={About} />
          <Route path="/profile" component={Profile} />
          <Route path="/updateDetails" component={UpdatePersonalDetails} />
          <Route path="/orders" component={Orders} />
          <Route path="/paymentSuccess" component={PaymentSuccess} />
          <Route path="/paymentError" component={PaymentError} />
        </Switch>
      </main>

      <Footer auth={auth}></Footer>
    </div>
  ) : (
    <div className={classes.root}>
      <CssBaseline />
      {showHeaderAndFooter ? (
        <TopBar updateShowFilter={updateShowFilter}></TopBar>
      ) : null}
      <main
        className={
          showHeaderAndFooter ? classes.content : classes.noHeaderAndFooter
        }
      >
        <Switch>
        <Route
            exact
            path="/"
            render={(props) => <Home {...props} showFilter={showFilter} updateShowFilter={updateShowFilter} />}
          />
          <Route path="/login" component={Login} />
          <Route path="/product/:id" component={ProductDetails} />
          <Route path="/resetPassword" component={ResetPassword} />
          <Route path="/register" component={Register} />
          <Route path="/cart" component={Cart} />
          <Route path="/about" component={About} />
          <Route path="/profile" component={Home} />
          <Route path="/orders" component={Orders} />
        </Switch>
      </main>

      {showHeaderAndFooter ? <Footer auth={auth}></Footer> : null}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  incrementCart: (counter, items) => {
    dispatch(incrementCartItems(counter, items));
  },
});

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    inventory: state.firestore.inventory,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AppContainer
);
