import React from "react";
import Checkout from "./Checkout";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import CartItem from "./CartItem";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { incrementCartItems } from "../../store/actions/cartActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    minHeight: "100vh",
    margin: "0 auto",
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
    },
  },

  heading: {
    fontSize: 16,
    fontWeight: 700,
    color: "#1CB5E0",
    marginLeft: 20,
  },

  total: {
    fontSize: 30,
    textAlign: "center",
    paddingTop: 10,
  },
  backToShop: {
    textAlign: "center",
    padding: 6,
    color: theme.palette.secondary.main,
  },
  content: {
    textAlign: "center",
    margin: "0 auto",
    padding: 5,
  },
  shippingFee: {
    fontSize: 12,
  },
  login: {
    margin: 20,
    backgroundColor: "#1CB5E0",
    color: "white",
    marginBottom: 35,
  },
  checkoutContainer: {
    marginBottom: 56,
  },
}));

const Cart = ({ auth, items, updateCart }) => {
  let cartItems = items || [];
  const classes = useStyles();
  const deliveryCost = 70; // remove magic number, this should be configureable
  //const totalCost = cartItems.reduce((a, c) => a + c.price * c.count, 0) + deliveryCost;

  let totalCost = 0;

  cartItems.forEach((x) => {
    if (x.sale) {
      totalCost = totalCost + x.salePrice * x.count;
    } else {
      totalCost = totalCost + x.price * x.count;
    }
  });

  totalCost = totalCost + deliveryCost;

  const removeFromCart = (product) => {
    const cartItemCopy = cartItems.slice(); // clone copy
    let updatedCart = cartItemCopy.filter((x) => x.name !== product.name);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    let counter = localStorage.getItem("cartCounter");
    counter = counter - product.count;
    if (counter < 0) counter = 0;
    localStorage.setItem("cartCounter", JSON.stringify(counter));
    cartItems = updatedCart;
    updateCart(counter, cartItems);
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.heading}>Cart</Typography>
      {cartItems && cartItems.length === 0 ? (
        <div className={classes.content}>
          <Typography> Cart is empty</Typography>
          <Button
            className={classes.login}
            variant="contained"
            style={{ textTransform: "none" }}
            component={Link}
            to="../"
          >
            Continue Shopping
          </Button>
        </div>
      ) : null}

      <div id="cartItem">
        {cartItems.map((item) => (
          <CartItem item={item} remove={removeFromCart} />
        ))}
      </div>

      {cartItems.length > 0 ? (
        <div id="cartInfo" className={classes.content}>
          <Typography className={classes.shippingFee}>
            Shipping Fee: R{deliveryCost}{" "}
          </Typography>
          <div id="total" className={classes.total}>
            R {totalCost}
          </div>

          {!auth.uid ? (
            <div className={classes.content}>
              <Typography>Please login to continue shopping</Typography>
              <Button
                className={classes.login}
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }}
                component={Link}
                to="../login"
              >
                Go To Login
              </Button>
            </div>
          ) : (
            <div className={classes.checkoutContainer}>
              <Checkout cartItems={cartItems} totalCost={totalCost} />
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateCart: (counter, items) => {
    dispatch(incrementCartItems(counter, items));
  },
});

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    items: state.cart.items,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Cart);
