import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";



const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  edit: {
    marginLeft: "auto",
    fontSize: 16
  },
  card: {
    margin: "0 auto",
    width: "90%",
    marginTop: 100,
    padding: 20,
    [theme.breakpoints.up("sm")]: {
      padding: 10,
      width: "65%",
      margin: "0 auto",
      marginTop: 100,
    }
  },
  header: {
    fontSize: 16,
    fontWeight: 600,
    color: "#1CB5E0"
  },
  text: {
    fontSize: 14
  }
}));

export default function PersonalDetails({
  email,
  name,
  address,
  suburb,
  city,
  phone
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.CardContent}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.header}
        >
          {email}
          <IconButton aria-label="edit" component={Link} to="../UpdateDetails">
            <EditIcon className={classes.edit} />
          </IconButton>
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.text}
        >
          {name}
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.text}
        >
          {address}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.text}
        >
          {suburb}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.text}
        >
          {city}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.text}
        >
          {phone}
        </Typography>
      </CardContent>
    </Card>
  );
}
