import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import OrderCard from "./OrderCard";
import PageLoader from "../../layout/PageLoader";
const useStyles = makeStyles(theme => ({
  root: {
    width: "90%",
    minHeight: "100vh",
    margin: "0 auto",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto"
    }
  },
  heading: {
    fontSize: 16,
    fontWeight: 700,
    color: "#1CB5E0",
    padding: 8
  },
  subheading: {
    fontSize: 12,
    fontWeight: 500,
    color: "#000046",
    paddingLeft: 8,
    paddingBottom: 10
  },

  content: {
    fontSize: 14,
    fontWeight: 500,
    color: "#000046",
    padding: 10
  },
  orderList: {
    padding: 10
  }
}));

const Orders = ({ auth, orders, settings }) => {
  const classes = useStyles();
  const [hideLoader, setHideLoader] = useState(false);
  let user = {};

  if (auth) {
    user = {
      id: auth.uid,
      email: auth.email
    };
  }

  setTimeout(() => {
    setHideLoader(true);
  }, 2000);

  let myOrders = orders.filter(order => {
    return order.email === user.email;
  });

  return !hideLoader ? (
    <div className={classes.overlay}>
      <PageLoader />
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.orderList}>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.heading}
        >
          Order History
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.subheading}
        >
          Amount of orders placed: {myOrders.length}
        </Typography>

        {myOrders.length > 0 ? (
          myOrders.map(order => {
            return <OrderCard order={order} settings={settings}></OrderCard>;
          })
        ) : (
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.content}
          >
            No Previous Orders
          </Typography>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    orders: state.firestore.ordered.orders || [],
    settings: state.firestore.ordered.settings
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{ collection: "orders" }, { collection: "settings" }])
)(Orders);
