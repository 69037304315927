import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useFirebase } from "react-redux-firebase";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import logo from "../../loaderWhite.png";
const useStyles = makeStyles(theme => ({
  root: {
    background:
      "radial-gradient(circle, rgba(92,171,217,1) 0%, rgba(54,133,181,1) 49%, rgba(3,64,102,1) 100%)",
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  header: {
    textAlign: "center",
    fontSize: 25,
    fontWeight: 700,
    color: "white",
    margin: "0 auto",
    padding: 20
  },
  text: {
    margin: "0 auto",
    textAlign: "center",
    width: 300,
    marginBottom: 10,
    background: "white",
    padding: 10,
    borderRadius: 30
  },
  finePrint: {
    margin: "0 auto",
    marginTop: 5,
    textAlign: "center"
  },
  finePrintText: {
    fontSize: 14,
    color: "white",
    textDecoration: "none",
    [theme.breakpoints.down("xs")]: {
      fontSize: 10
    }
  },
  message: {
    textAlign: "center",
    color: "red",
    fontSize: 14
  },
  register: {
    marginLeft: 20,
    width: 100,
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: "#E5A718",
    color: "white"
  },
  login: {
    width: 100,
    fontSize: 14,
    fontWeight: 600,
    color: "white"
  },
  container: {
    width: 300,
    margin: "0 auto"
  },
  logo: {
    width: 145,
    margin: "0 auto"
  },
  input: {
    "&::placeholder": {
      textAlign: "center"
    }
  }
}));

const Login = () => {
  const classes = useStyles();
  const firebase = useFirebase();
  const [state, setState] = useState({
    email: "",
    password: ""
  });
  const [errorMessage, setErrorMessage] = React.useState(false);

  const handleChange = e => {
    const { id, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleSubmitClick = e => {
    e.preventDefault();
    signIn();
  };

  const signIn = e => {
    firebase
      .auth()
      .signInWithEmailAndPassword(state.email, state.password)
      .then(user => {
        console.log("signed in");
        window.location.href = "../";
      })
      .catch(error => {
        var errorMessage = error.message;
        console.log(errorMessage);
        setErrorMessage(errorMessage);
      });
  };

  return (
    <div className={classes.root}>
      <img src={logo} className={classes.logo} alt="logo" />
      <Typography className={classes.header}> Login</Typography>

      <div className={classes.container}>
        <TextField
          className={classes.text}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input }
          }}
          InputLabelProps={{ required: false }}
          id="email"
          type="email"
          required
          placeholder="Email"
          onChange={handleChange}
        />

        <TextField
          className={classes.text}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.input }
          }}
          InputLabelProps={{
            required: false
          }}
          id="password"
          type="password"
          required
          placeholder="Password"
          onChange={handleChange}
        />
      </div>

      <Grid container justify="center" style={{ marginTop: "30px" }}>
        <Button
          className={classes.login}
          variant="contained"
          color="primary"
          style={{ textTransform: "none" }}
          onClick={handleSubmitClick}
        >
          Login
        </Button>

        <Button
          className={classes.register}
          variant="contained"
          style={{ textTransform: "none" }}
          component={Link}
          to="../register"
        >
          Register
        </Button>
      </Grid>

      <Typography className={classes.finePrint}>
        <Link to="../resetPassword" className={classes.finePrintText}>
          Forgot Password
        </Link>
      </Typography>

      <Typography className={classes.finePrint}>
        <Link to="../" className={classes.finePrintText}>
          Back to Shop
        </Link>
      </Typography>

      <div className={classes.message}>
        {errorMessage ? <p> {errorMessage}</p> : null}
      </div>
    </div>
  );
};
export default Login;
