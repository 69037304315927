import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {},
  order: {
    margin: 0,
    padding: 8,
    [theme.breakpoints.up("sm")]: {
      padding: 10,
      width: "60%",
      margin: "0 auto"
    }
  },
  header: {
    fontSize: 14,
    fontWeight: 600,
    color: "#2f2f2f",
    padding: 4
  },
  date: {
    fontSize: 10,
    paddingLeft: 2
  },
  active: {
    fontSize: 12,
    color: "#E5A718",
    paddingLeft: 2,
    padding: 4,
    fontWeight: 500
  },
  completed: {
    fontSize: 14,
    color: "#E5A718",
    paddingLeft: 2,
    padding: 4,
    fontWeight: 500
  },
  trackingNumberLink: {
    paddingTop: 4,
    paddingLeft: 2,
    fontSize: 12,
    fontWeight: 600,
    color: "#1CB5E0"
  }
}));

const handleClick = (e, trackingLink) => {
  e.preventDefault();
  window.open(trackingLink, "_blank");
};

export default function OrderItem({ order, settings }) {
  const classes = useStyles();
  let trackingLink = "";
  let trackingNumberLink = "";
  let settingsDeliveryUrl = "";

  if (settings && order.courierCompany) {
    let courierCompany = order.courierCompany;
    let trackingNumber = order.trackingNumber;

    if (settings[0].deliveryInfo[courierCompany]) {
      settingsDeliveryUrl = settings[0].deliveryInfo[courierCompany];
      console.log(settingsDeliveryUrl);
      settingsDeliveryUrl = settingsDeliveryUrl.trackingUrl.replace(/ /g, "");
    }

    trackingLink = settingsDeliveryUrl + trackingNumber;

    trackingNumberLink = (
      <div
        className={classes.trackingNumberLink}
        onClick={e => handleClick(e, trackingLink)}
      >
        {trackingNumber}
      </div>
    );
  }

  return (
    <Card className={classes.order}>
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.header}
      >
        {order.items.map(item => {
          return (
            <div>
              {item.name} - R{order.totalPaid}
            </div>
          );
        })}
      </Typography>


      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.date}
      >
        {order.count ? "Quantity: " + order.count : "Quantity: 1 " }
      </Typography>



      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={order.isComplete ? classes.completed : classes.active}
      >
        {order.isComplete
          ? "Order has been completed "
          : "Order is currently in progress"}
      </Typography>

      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.date}
      >
        {new Date(order.history[0].date).toDateString()}
      </Typography>

      <Typography variant="body2" color="textSecondary">
        {trackingNumberLink}
      </Typography>
    </Card>
  );
}
