import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Link } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import ViewListIcon from "@material-ui/icons/ViewList";
const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: 2
    },
    height: 54,
    position: "fixed",
    left: 0,
    bottom: 0,
    zIndex: 5000,
    backgroundColor: "#fff",
    width: "100%"
  },
  icon: {
    // color: "#3a3e4a"
  }
}));

const Footer = ({ auth }) => {
  const classes = useStyles();

  return (
    <div>
      <BottomNavigation className={classes.root} showLabels>
        <BottomNavigationAction
          label="Home"
          component={Link}
          to="../"
          icon={<HomeIcon className={classes.icon} />}
        />
        <BottomNavigationAction
          label="About"
          component={Link}
          to="../about"
          icon={<InfoIcon className={classes.icon} />}
        />

        {auth.email ? (
          <BottomNavigationAction
            label="Orders"
            component={Link}
            to="../orders"
            icon={<ViewListIcon className={classes.icon} />}
          />
        ) : null}

        {auth.email ? (
          <BottomNavigationAction
            label="Account"
            component={Link}
            to="../profile"
            icon={<AccountCircleIcon className={classes.icon} />}
          />
        ) : (
          <BottomNavigationAction
            label="Login/Register"
            component={Link}
            to="../login"
            icon={<AccountCircleIcon className={classes.icon} />}
          />
        )}
      </BottomNavigation>
    </div>
  );
};

export default Footer;
