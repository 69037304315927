import authReducer from "./authReducer";
import { combineReducers } from "redux";
import orderReducer from "./orderReducer";
import cartReducer from "./cartReducer";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

const rootReducer = combineReducers({
  auth: authReducer, //updates info on the auth property
  order: orderReducer, //updates info on the order property
  cart: cartReducer, //updates info on the order property
  firestore: firestoreReducer,
  firebase: firebaseReducer
});

export default rootReducer;
